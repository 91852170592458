import clsx from 'clsx';
import { Form as FormikForm, FormikFormProps, useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';
import { mergeProps } from 'react-aria';
import { FormContext, FormValidationContext } from 'react-aria-components';
import { ValidationError as YupValidationError } from 'yup';

import { DATA } from './consts';
import { FormInputProps } from './useFormInput';

type FormProps = { name?: string } & FormikFormProps;

export const Form = ({ className, ...props }: FormProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { isSubmitting, isValidating, errors, submitCount } = useFormikContext<{
    [key: string]: any;
  }>();

  const isInvalid = Object.keys(errors).length > 0;
  const totalSubmitCountRef = React.useRef<number>(submitCount);

  useEffect(() => {
    const form = formRef.current;

    if (!form) {
      return;
    }

    if (
      submitCount > totalSubmitCountRef.current &&
      !isValidating &&
      isInvalid
    ) {
      totalSubmitCountRef.current = submitCount;

      const invalidInputs = form.querySelectorAll(
        `[${DATA.VALIDATION}='invalid']`
      );

      if (invalidInputs.length === 0) {
        return;
      }

      const [first] = invalidInputs;
      const selector = getInputSelector(first);

      first.scrollIntoView();
      first.querySelector(selector)?.focus();
    }
  }, [submitCount, isValidating, isInvalid]);

  return (
    <FormikForm
      ref={formRef}
      className={clsx('hlx-form', className)}
      {...props}
      method={props.method || 'POST'}
    />
  );
};

function getInputSelector(
  el: Element
): NonNullable<FormInputProps<any>['inputElementType']> {
  const data = el.getAttribute(DATA.INPUT_TYPE);
  return data === 'textarea' ? 'textarea' : 'input';
}

interface FormStickyFooterProps {
  children: React.ReactNode;
}
export function FormStickyFooter(props: FormStickyFooterProps) {
  const { errors, submitCount } = useFormikContext<{ [key: string]: any }>();

  return (
    <div className="hlx-form-sticky-footer">
      <div className="hlx-form-sticky-footer-content">
        {!!Object.keys(errors).length && submitCount > 0 && (
          <span className="hlx-form-sticky-footer-error">
            There is an issue with a field above.
          </span>
        )}
        {props.children}
      </div>
    </div>
  );
}

type FormWithValidationBaseProps = {
  children: React.ReactNode;
  validations?: FieldValidation[];
};

type FormWithValidationProps<
  T extends 'form' | React.JSXElementConstructor<any>,
> = FormWithValidationBaseProps &
  Omit<React.ComponentProps<T>, keyof FormWithValidationBaseProps> & {
    component?: T;
  };

export interface FieldValidation {
  name: string;
  validations: string[];
}

function FormWithValidation<
  T extends 'form' | React.JSXElementConstructor<any> = 'form',
>(
  {
    component: FormComponent = 'form' as T,
    validations,
    ...props
  }: FormWithValidationProps<T>,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  let groupedByField: Record<string, string[]> = {};

  for (const validation of validations ?? []) {
    if (!groupedByField[validation.name]) {
      groupedByField[validation.name] = [];
    }

    const messages = validation.validations;

    groupedByField[validation.name].push(...messages);
  }

  return (
    // @ts-expect-error
    <FormComponent
      noValidate={true}
      ref={ref}
      // @ts-expect-error
      {...mergeProps(props, {
        className: 'hlx-form',
      })}
    >
      <FormContext.Provider value={{ ...props, validationBehavior: 'native' }}>
        <FormValidationContext.Provider value={groupedByField}>
          {props.children}
        </FormValidationContext.Provider>
      </FormContext.Provider>
    </FormComponent>
  );
}

const _FormWithValidation = React.forwardRef(FormWithValidation) as <
  T extends 'form' | React.JSXElementConstructor<any> = 'form',
>(
  p: FormWithValidationProps<T> & { ref?: React.Ref<HTMLFormElement> }
) => React.ReactElement;

export { _FormWithValidation as FormWithValidation };

export function toFormValidations(
  error: YupValidationError
): FieldValidation[] {
  return error.inner.map((e) => ({
    name: e.path,
    validations: e.errors.map((message) => {
      return message;
    }),
  }));
}
